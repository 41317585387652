<script setup lang="ts">
import type { Schemas } from '#shopware';

const props = defineProps<{
    showMax: number | null;
    navItems: Schemas['Category'][];
    parentLink: string;
    currentProductCategoryId: string | undefined;
}>();

const { showMax, navItems } = toRefs(props);

const slicedNavItems = computed(() => {
    if (showMax.value && showMax.value < navItems.value.length) {
        return navItems.value.slice(0, showMax.value);
    }

    return navItems.value;
});
const isActiveProductCategory = (categoryId: string) => props.currentProductCategoryId === categoryId;
</script>

<template>
    <template
        v-for="navItem in slicedNavItems"
        :key="navItem.id"
    >
        <LayoutCategory
            :category="navItem"
            class="flex justify-between rounded-lg hover:text-brand-primary transition-colors"
            :class="{ 'text-brand-primary': isActiveProductCategory(navItem.id) }"
        />
    </template>
    <NuxtLink
        v-if="showMax"
        :to="parentLink"
        class="flex justify-between rounded-lg hover:!text-brand-primary transition-colors underline mt-1 !text-gray-500"
    >
        {{ $t('showAll') }}
    </NuxtLink>
</template>
